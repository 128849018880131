<template v-if="alert">
  <v-layout row justify-center>
    <v-dialog v-model="alert.dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>{{alert.message}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="alert.confirmLink" text @click.native="close">{{ alert.cancle}}</v-btn>
          <v-btn v-else  color="primary" text @click.native="close">{{ alert.ok }}</v-btn>
           <v-btn v-if="alert.confirmLink" color="primary" text @click.native="confirm">{{ alert.ok}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import { PageService } from "@/common/api.service";

import { SET_ALERT_RESET, SET_ALERT_RESPONSE, PURGE_AUTH,FETCH_START,
  FETCH_END } from "../store/mutations.type";
import { FETCH_PAGE } from "../store/actions.type";

export default {
  name: "AlertDialog",
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    close() {
      if (this.alert.login) {
        this.$store.commit(PURGE_AUTH); 
        this.$router.push({ name: "login" });
      }
      this.$store.commit(SET_ALERT_RESET); 
     
    },
    confirm() {
      if (this.alert.confirmPageParam) {
        this.$store.commit(FETCH_START);
        PageService.query(this.alert.confirmLink)
        .then(() => {
          this.$store.commit(FETCH_END);
          this.$store.dispatch(FETCH_PAGE, this.alert.confirmPageParam);
          this.$store.commit(SET_ALERT_RESET);
        })
        .catch(({ response }) => {
          this.$store.commit(FETCH_END);
          this.$store.commit(SET_ALERT_RESPONSE, response);
        });           
      } else {
        this.$router.push(this.alert.confirmLink); 
        this.$store.commit(SET_ALERT_RESET);
      }
    }
  }
};
</script>
