
<template>
    <v-toolbar-title >
      <div v-if="title" >{{ title }}</div>
      <div v-else-if="daten && daten.kontext">       
        <v-icon v-if="daten.kontext.icon" >{{ daten.kontext.icon }}</v-icon> {{ daten.kontext.titel }}
        <div class="ci-untertitel" v-if="daten.kontext.untertitel" > {{ daten.kontext.untertitel }}</div>      
      </div>
    </v-toolbar-title>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QHeaderTitle",
  props: ["title"],
  computed: {
    ...mapGetters(["daten"]),
  }
};
</script>
