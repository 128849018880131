
<template>
  <div>
    <v-app-bar  :color="layoutColor('header')"   :dark="layoutDark('header')" :light="layoutLight('header')" app  fixed :extensionHeight="extensionHight()">
      <v-btn  icon v-if="backLink" :disabled="isRequesting"  :to="backLink" >
        <v-icon> mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn icon v-else-if="daten && daten.zurueck" :disabled="isRequesting"   @click="navi(daten.zurueck)" >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-app-bar-nav-icon v-else @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
          <HeaderTitle  :title="title" />
      <template v-if="daten && (daten.menue || daten.pfad)" #extension>
        <v-container  v-if="daten && (daten.pfad || daten.menue)" fluid style="padding: 0">
            <HeaderPfad/>
            <HeaderMenue />
        </v-container>
      </template>
      <template  v-if="daten && daten.aktionsmenue">
        <v-spacer></v-spacer>
        <v-btn  v-for="(item, index) in daten.aktionsmenue" :disabled="isRequesting"  :key="item.text + index" icon @click="navi(item)"><v-icon>{{ item.icon }}</v-icon></v-btn>
      </template>
      <v-progress-linear v-if="isLoading" fixed top indeterminate :color="layoutColor('header_progressbar')"   :dark="layoutDark('header_progressbar')" :light="layoutLight('header_progressbar')" ></v-progress-linear>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
        <div><v-img :src="images.logo" /></div>
        <v-divider></v-divider>
        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item v-if="isAuthenticated" to="/kennwort">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Kennwort ändern</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  v-if="isAuthenticated" @click="logout()">
              <v-list-item-icon>
                <v-icon>logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Abmelden</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  v-if="!isAuthenticated"  to="/kennwort-vergessen">
            <v-list-item-icon>
              <v-icon>edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kennwort vergessen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>            
           <v-list-item @click="staticLink(impressum_url)">
              <v-list-item-icon>
                <v-icon>info</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Impressum</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="staticLink(datenschutz_url)">
              <v-list-item-icon>
                <v-icon>security</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Datenschutz</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
                        <!--
                        <v-list-item  to="/schema-editor">

              <v-list-item-content>
                <v-list-item-title>Schema</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

              <v-list-item to="/schema-editor">
              <v-list-item-icon>
                <v-icon>security</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>TEST</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            -->
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { NAVI_PAGE, LOGOUT, SUCHBUTTON, GET_BLOB} from "../store/actions.type";

import HeaderMenue from "@/components/HeaderMenue.vue";
import HeaderPfad from "@/components/HeaderPfad.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import { bus } from '../main'


export default {
  name: "QHeader",
  props: ["title", "backLink"],
  components: {
    HeaderMenue,
    HeaderPfad,
    HeaderTitle,

},
  data() {
    return {
      drawer: null,
      impressum_url: process.env.VUE_APP_IMPRESSUM_URL,
      datenschutz_url: process.env.VUE_APP_DATENSCHUTZ_URL,
      images: {
        logo: require('../assets/logo.png')
      }
    }},    
  computed: {
    ...mapGetters(["daten", "isRequesting", "isLoading", "isAuthenticated"])
  },
  methods: {
    navi(item) {
        if (item.search) {
          this.$store.dispatch(SUCHBUTTON, item)
        } else  if (item.blobname) {
          this.$store.dispatch(GET_BLOB, item)
        } else  if (item.submit) {
           bus.$emit('einzelsatzSubmit', item.navi);
        } else{
          this.$store.dispatch(NAVI_PAGE, item)
        }     
    },      
    extensionHight() {
      let daten = this.$store.getters.daten;
      let extensionHight = 0;
      if (daten && daten.pfad) {
        extensionHight = extensionHight + 48;
      }

      if (daten && daten.menue) {
        extensionHight = extensionHight + 48;
      }


      /*
      if (this.$store.getters.zeigeSuchfelder) {
        extensionHight = extensionHight + 0;
      }
      */
      return extensionHight;
    },
    staticLink(link) {
      this.drawer = false;
      window.open(link, "_blank");
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "login" });
      });
    },    
  }
};
</script>
