
<template v-if="daten">
<v-sheet  v-if="daten.menue"  id="ci-menue"   :color="layoutColor('header_menue')" :light="layoutLight('header_menue')"  :dark="layoutDark('header_menue')"  >
 <v-tabs
    v-if="daten.menue"  
    align-with-title
 
    background-color="transparent"
    show-arrows
    center-active
    v-model="selected"
  >
    <v-tab v-if="daten.kontext" v-bind:key="0" @click="navi(daten.kontext)"   > 
      <v-icon>info</v-icon>
    </v-tab>
    <v-tab
      v-for="(item, index) in daten.menue"
      :item="item"
      v-bind:key="index + 1"
      @click="navi(item)"
    >{{ item.titel }}</v-tab>
  </v-tabs>
</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { NAVI_PAGE} from "../store/actions.type";

import { SET_SELECTED_MENUE } from "../store/mutations.type";
export default {
  name: "QHeaderMneue",
  methods: {
    navi(item) {
      this.$store.dispatch(NAVI_PAGE, item);
    }
  },
  computed: {
    ...mapGetters(["daten"]),
    selected: {
      get() {
        return this.$store.getters.selectedMenue;
      },
      set(value) {
        this.$store.commit(SET_SELECTED_MENUE, value);
      }
    }
  }
};
</script>
