
<template v-if="daten">
    <v-footer absolute app >
        <v-container>
            <v-row >
                <v-col cols="12" sm="3">
                    <div  class="ci-footer-content">
                        {{footer_copy}} {{footer_org}}
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <div class="ci-footer-content ci-center" v-if="daten && daten.benutzerinfo && daten.benutzerinfo.email">{{ daten.benutzerinfo.email }}</div>
                </v-col>
                <v-col cols="12" sm="3">
                    <div  class="ci-footer-content ci-right">
                         APP: {{version}}
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-footer> 
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QFooter",
  data() {
    return {
      footer_org: process.env.VUE_APP_FOOTER_ORG,
      footer_copy: process.env.VUE_APP_FOOTER_COPYRIGHT,
      version: process.env.VERSION
    };
  },  
  computed: {
    ...mapGetters([ "daten"])
  }
};
</script>
