
<template v-if="daten">
<div  v-if="daten.pfad" row no-gutters id="ci-path" class="ci-header-extension" >
    <div   style="width: 100% ; max-width: 100%">
        <div class="ci-path-wrapper">
            <div  v-if="daten.pfad"  class="ci-first">
            <span  @click="navi( daten.pfad[0])"><span  v-bind:class="{ 'ci-is-navi' :  daten.pfad[0].navi }">{{ daten.pfad[0].titel }}</span><span class="ci-deliminiter">></span></span>
            </div>
            <div class="ci-rest">
            <template v-for="(item, index) in daten.pfad" :index="index"   > 
                <template v-if="daten.pfad[0].id  != item.id"  >                  
                    <span class="ci-item"  @click="navi(item)"  v-bind:key="'1-' + item.id"><span  v-bind:class="{ 'ci-is-navi' : item.navi }">{{ item.titel }}</span><span v-if="index != daten.pfad.length - 1" class="ci-deliminiter">></span></span>
                </template>
            </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { NAVI_PAGE} from "../store/actions.type";

export default {
  name: "QHeaderPfad",
  methods: {
    navi(item) {
      this.$store.dispatch(NAVI_PAGE, item);
    }
  },
  computed: {
    ...mapGetters(["daten"]),
  }
};
</script>
